*:focus {
    outline: none;
}

.ant-menu-horizontal > .ant-menu-item-active {
    border-top: 2px solid #1890ff !important;
    border-bottom: none !important;
}

.logo img {
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
}

.sidebar {
    min-height: 87.9vh;
}


.footer {
    border-top: 1px solid #e1e1e1
}

.header {
    padding: 0px;
    background-color: #f3f8ff;
}

.content, .footer {
    background-color: #fff;
}

.stacked {
   line-height: normal;
   align-self: center;
}

.content {
    padding: 0px 20px;
}

.mr-25 {
    margin-right: 25px
}

.mr-20 {
    margin-right: 20px
}

.mr-15 {
    margin-right: 15px
}

.mr-10 {
    margin-right: 10px
}

.mr-5 {
    margin-right: 5px;
}

.ml-25 {
    margin-left: 25px
}

.ml-20 {
    margin-left: 20px
}

.ml-15 {
    margin-left: 15px
}

.ml-10 {
    margin-left: 10px
}

.ml-5 {
    margin-left: 5px;
}

.mt-25 {
    margin-top: 25px
}

.mt-20 {
    margin-top: 20px
}

.mt-15 {
    margin-top: 15px
}

.mt-10 {
    margin-top: 10px
}

.mt-5 {
    margin-top: 5px;
}

.mb-25 {
    margin-bottom: 25px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-5 {
    margin-bottom: 5px;
}

.pr-25 {
    padding-right: 25px
}

.pr-20 {
    padding-right: 20px
}

.pr-15 {
    padding-right: 15px
}

.pr-10 {
    padding-right: 10px
}

.pr-5 {
    padding-right: 5px;
}

.pl-25 {
    padding-left: 25px
}

.pl-20 {
    padding-left: 20px
}

.pl-15 {
    padding-left: 15px
}

.pl-10 {
    padding-left: 10px
}

.pl-5 {
    padding-left: 5px;
}

.pt-25 {
    padding-top: 25px
}

.pt-20 {
    padding-top: 20px
}

.pt-15 {
    padding-top: 15px
}

.pt-10 {
    padding-top: 10px
}

.pt-5 {
    padding-top: 5px;
}

.pb-25 {
    padding-bottom: 25px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-10 {
    padding-bottom: 10px
}

.pb-5 {
    padding-bottom: 5px;
}

.card {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    border-radius: 5px;
    height: 120px;
    display: flex !important;
    align-items: center;
}

.card h2 {
    z-index: 2;
}

.card-placeholder {
    font-size: 5em;
    position: absolute;
    display: flex !important;
    justify-self: center;
    right: 10px;
}

.card-yellow {
    background-color: rgba(241, 196, 15, .56);
    border: 2px solid #f1c40f;
}

.card-yellow > .card-placeholder {
    color: #f1c40f;
}

.card-red {
    background-color: rgba(231, 76, 60, .56);
    border: 2px solid #e74c3c;
}

.card-red > .card-placeholder {
    color: #e74c3c;
}

.card-green {
    background-color: rgba(46, 204, 113, .56);
    border: 2px solid #2ecc71;
}

.card-green > .card-placeholder {
    color: #2ecc71;
}

.card-purple {
    background-color: rgba(155, 89, 182, .56);
    border: 2px solid #9b59b6;
}

.card-purple > .card-placeholder {
    color: #9b59b6;
}

.card-turquoise {
    background-color: rgba(26, 188, 156,.56);
    border: 2px solid #1abc9c;
}

.card-turquoise > .card-placeholder {
    color: #1abc9c;
}

.card-blue {
    background-color: rgba(52, 152, 219,.56);
    border: 2px solid #3498db;
}

.card-blue > .card-placeholder {
    color: #3498db;
}

.card-orange {
    background-color: rgba(230, 126, 34, .56);
    border: 2px solid #e67e22;
}

.card-orange > .card-placeholder {
    color: #e67e22;
}

.pointer {
    cursor: pointer;
}

.img-logo {
    width: 150px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
}

.language-select {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 50px;
}

.bg-login {
    background-image: linear-gradient(rgba(30, 111, 203, 0.78), 
                    rgba(30, 111, 203, 0.95)), 
                    url('../img/bg.jpg');
    position: relative;
    background-size: cover;
}

.login-page h1 {
    color: #fff;
    font-size: 3em;
    font-weight: 600;
}

.login-left-section {
    text-align: center;
    color: #fff;
}

.login-left-section h1 {
    color: #fff;
    font-weight: 700;
    font-size: 3em;
}

.login-left-section p {
    font-weight: 300;
    font-size: 1.2em;
    margin-bottom: 30px;
}

.button-outline-login {
    color: #fff;
    background-color: transparent;
    box-shadow: none;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 50px;
    border-radius: 25px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.button-outline-login:hover {
    background-color: #fff;
    color: rgb(30, 111, 203);
}

.login-right-section {
    text-align: center;
}

.login-right-section h1 {
    color: rgb(30, 111, 203);
    font-weight: 700;
    font-size: 3em;
}

.login-social {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.login-social li {
    display: inline-block;
}

.btn-social {
    background-color: transparent;
    box-shadow: none;
    font-size: 1.25em;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px 5px;
    border: 1px solid #e1e1e1;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.btn-social:hover {
    background-color: rgb(30, 111, 203);
    color: #fff;
}

.button-outline-register {
    color: #fff;
    background-color: rgb(30, 111, 203);
    box-shadow: none;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 50px;
    border-radius: 25px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.button-outline-register:hover {
    background-color: #fff;
    color: rgb(30, 111, 203);
    border: 1px solid rgb(30, 111, 203);
}

.button-disabled {
    opacity: .5;
    cursor: not-allowed;
}

.menu-logo:hover, .menu-logo.ant-menu-item-active {
    border-bottom: 2px solid transparent !important;
    border-top: none !important;
    color: inherit !important;
}

.text-center {
    text-align: center;
}

.package {
    border: 1px solid #e1e1e1;
    padding: 20px;
    border-radius: 10px;
    background-color: #f3f8ff;
}

.package p {
    font-weight: 600;
    margin: 20px 0px 5px 0px;
}

.package ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.package ul > li, .package > span {
    color: #95A8B7;
}

.ant-upload-select-picture-card {
    width: 90% !important;
    height: 128px;
}

.button-absolute {
    position: absolute !important;
    right: 0px;
}